.listOfCustomersPageContainer {
  margin-top: 42px;
}

.listOfCustomersPageHeader {
  display: flex;
  justify-content: space-between;
}

.listOfCustomersPageTitle {
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.listOfCustomersPageControl {
  padding: 20px;
  background-color: #F4F7FA;
  border-radius: 4px;
  margin-top: 36px;
  display: flex;
}

.controlSearch {
  width: 780px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  background-color: #fff;
  padding-left: 14px;
  border-radius: 7px;
  margin-left: 20px;
}

.controlSearchInput {
  padding: 14px;
  width: 100%;
}

.rangeSelectWrapper {
  position: relative;

}

.rangeSelectBtn {
  position: relative;
  background-color: #fff;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease .1s;
  padding: 0 17px;

  &:hover {
    box-shadow: 0 0 3px rgba(31, 35, 38, 0.1);
    background-color: #FFE071;
  }

  &:hover .rangeSelectClearBtn {
    display: block;
  }
}

.rangeSelectClearBtn {
  position: absolute;
  top: 3px;
  right: 10px;
  display: none;

  &:hover {
   box-shadow: 0 0 2px #eeeeee;
  }
}

.rangeSelect {
  position: absolute;
  left: 0;
  top: 120%;
  z-index: 1;
  box-shadow: 0 0 10px rgba(12, 63, 126, 0.14);
}

@media screen and (max-width: 1600px) {
  .controlSearch {
    width: 700px;
  }
}
