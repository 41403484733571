@import "uikit/themes/styles/fonts/googleSans/stylesheet.css";
@import "uikit/themes/styles/fonts/publicSans/index.css";
@import "uikit/themes/styles/fonts/SFPro/sfPro";

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');


.ant-layout-header {
  height: 48px !important;
  padding: 16px !important;
}


//* {
//  margin: 0;
//  padding: 0;
//  font-family: 'SF Pro', Roboto, sans-serif;
//  box-sizing: border-box;
//  color: #041121;
//}

