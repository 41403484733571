.dateCard {
  padding: 10px;
  border: 1px solid gray;
}

.instructorCard {
  padding: 44px 0;
  border: 1px solid gray;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructorCard.selected {
  background-color: #e0e0e0;
}

.trainingsRow {
  display: flex;
  column-gap: 30px;
  overflow-x: auto;
  margin-top: 20px;
  background-color: #7eadad;
}

.addTrainingRow {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
}
