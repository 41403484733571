@import "../../../../uikit/colors";

.listOfTrainersWrapper {
  margin-top: 18px;
}

.listOfTrainersTable {
  background-color: #F4F7FA;
  width: 100%;
  border-collapse: collapse;

  & thead {
    font-weight: 500;
  }

  & td {
    border-right: 1px solid #B5BDC5;
    padding: 15px;
    &:last-child {
      border-right: none;
    }
  }

  & tr {
    border-bottom: 1px solid #B5BDC5;
  }
}

.listOfCustomersPageTitle {
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}

.editTrainerBtn {
  cursor: pointer;
  transition: all ease .3s;

  &:hover * {
    fill: $accent-gray;
  }
}
