@import "../../../uikit/colors";

.trainingRecordsList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 30px;
}

.todayDate {
  font-size: 22px;
}

.trainingRecordsInDayList {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
  padding-bottom: 50px;
}

.trainingRecord {
  border-radius: 12px;
  border: 1px solid $gray;
  padding: 11px 20px;
  display: grid;
  column-gap: 18px;
  grid-template-columns: 1fr 2fr 2fr 1fr 2fr 3fr 1fr;
  align-items: center;
  font-size: 14px;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nameTrainer {
  font-weight: 600;
}

.avatarTrainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.emailTrainer, .phoneTrainer {
  color: $accent-gray;
}

.seeTranslationLink {
  color: $accent-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}