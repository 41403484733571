.cardWrapper {
  background-color: #e1e1e1;
  display: flex;
  column-gap: 10px;
  padding: 10px;
  width: 100%;
}

.avatar {
  width: 100px;
  height: auto;
}
