$primary-blue: #3E7EFF;
$accent-blue: #3E7EFF;
$white: #fff;
$black: #171721;
$gray: #E4E6E8;
$light-gray: #F5F5FA;
$transparency-gray: rgba(228, 230, 232, 0.6);
$accent-gray: #8083A3;
$accent-red: #FF4646;
$primary-green: #5FDCB3;
$accent-yellow: #F8C925;

:export {
  accentBlue: $accent-blue
}