.instructorsList {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px
}

.selectedInstructors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.instructorNumberCard {
  min-height: 100px;
  background-color: #A09BAE;
  display: flex;
  align-items: center;
  justify-content: center;
}
