.cardContainer {
  background-size: cover;
  background-position: center;
  padding: 19px 24px 9px 24px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  border-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.marathonInterval {
  background-color: #fff;
  width: min-content;
  white-space: nowrap;
  padding: 9px 12px;
  margin-top: 10px;
}

.marathonStatus {
  display: flex;
  column-gap: 10px;
  margin-top: 6px;
  align-items: center;
}

.marathonUsers {
  display: flex;
  column-gap: 4px;
  align-items: center;
  background-color: #E1DFE7;
  padding: 4px 7px;
  border-radius: 4px;
}
