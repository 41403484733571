.authPage {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px;
}

.imagePart {
  background: rgba(61, 93, 157, 0.1);
  position: relative;
  background: linear-gradient(90deg, rgba(84, 121, 192, 0.3) 0%, rgba(29, 87, 203, 0.05) 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  overflow: hidden;
}

.imagePartText {
  margin-top: 100px;
  font-size: 64px;
  font-weight: 600;
  z-index: 1;
}

.imagePartImg {
  position: absolute;
  right: -7%;
  bottom: -5%;
}

.formPart {
  margin-top: 50px;
}

.formWrapper {
  margin: 0 auto;
  width: 300px;
  height: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 80px;
}

.title {
  font-weight: 500;
  font-size: 36px;
  color: #434D5A;
  text-align: center;
  line-height: 1.1em;
  margin-bottom: 43px;
}
