.formContainer {
  width: 100%;
  margin-top: 30px;
}

.photos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 30px;
}

.fullPhoto {
  background-position: center;
  background-size: cover;
  background-color: #949494;
  display: block;
  position: relative;
  width: 200px;
  margin-top: 7px;
}

.fullPhoto.sq {
  height: 200px;
}

.fullPhoto.long {
  height: 339px;
}

.fullPhoto.middle {
  height: 156px;
}

.trash, .uploadIcon {
  position: absolute;
  top: 15px;
  padding: 3px;
  cursor: pointer;
}

.trash {
  right: 15px;
}

.uploadIcon {
  right: 50px;
}

.trash:hover {
  background-color: red;
}

.uploadIcon:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
