.marathonsListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 19px;
  column-gap: 10px;
  padding: 30px 120px 30px 32px;
}

.marathonsControllers {
  padding: 10px;
}

.goToCreateMarathon {
  background-color: #A09BAE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  color: #ffffff;
}
