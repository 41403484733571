.logo {
  display: flex;
  align-items: center;
  height: 100%;

  & h4 {
    color: #ffffff !important;
    padding: 0 !important;
    font-size: 18px;
    margin: 0 !important;
  }
}

