.firstStepContainer {
  display: grid;
  grid-template-columns: 4fr 5fr;
  column-gap: 5px;
  margin-top: 5px;
  background-color: #fff;
  padding: 15px;
}

.firstStepImage {
  background-color: #e2e2e2;
}
